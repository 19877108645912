export function isEmbeddedUrlReplacer(text) {
  if (!text) return null;
  return text.replaceAll(/(href=")(https?:\/\/[^\s]+)(["'])/g, (match, g1, g2, g3) => {
    try {
      if (!g1 || !g2 || !g3) {
        return match;
      }
      return g1 + addIsEmbedded(g2) + g3;
    } catch {
      return match;
    }
  });
}

export function addIsEmbedded(text) {
  if (!text) return text;
  const url = new URL(text);
  url.searchParams.append("is_embedded", true);
  return url.toString();
}